import { Fragment, useState, useRef, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  CalendarDaysIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import Image from "next/image";
import Stats from "./stats";
import toast from "react-hot-toast";
import { ShareIcon } from "@heroicons/react/24/solid";
import { trackClick } from "../util/tracking.js";
import { getTwoferLink } from "../util/referrer";
import { PUZZLE_STATES } from "./puzzle";
import { format } from 'date-fns-tz';
import { parseISO } from "date-fns";
import useSWR from 'swr';
import Router from "next/router";

function secondsToStr(sec) {
  if (sec < 60) {
    return `${sec} seconds`;
  }
  const min = Math.floor(sec / 60);
  const remainderSec = sec - min * 60;
  return `${min} min ${remainderSec} sec`;
}

function buildHintStr(numHintsUsed) {
  if (numHintsUsed == 0) {
    return "🤯 No hints!";
  } else if (numHintsUsed == 1) {
    return "🕵️ 1 hint";
  } else {
    return `🕵️ ${numHintsUsed} hints`;
  }
}

function buildDate(puzzleDay) {
  // Parse the date string
  const date = parseISO(puzzleDay);

  // Format the date
  const formattedDate = format(date, 'MMM dd, yyyy');

  return `📅 ${formattedDate}`;
}

function buildTimeStr(puzzleTimeSec, topPercent) {
  if (topPercent && topPercent <= 10) {
    return `⚡ ${secondsToStr(puzzleTimeSec)} (Top ${topPercent}%)`;
  } else if (topPercent) {
    return `⏱️ ${secondsToStr(puzzleTimeSec)} (Top ${topPercent}%)`;
  } else {
    return `⏱️ ${secondsToStr(puzzleTimeSec)}`;
  }
}

function buildThumbprint(
  solved,
  puzzleNumber,
  numHintsUsed,
  timerEnabled,
  puzzleTimeSec,
  topPercent,
  puzzleDay,
) {
  const shareDataArr = [];
  shareDataArr.push(`Twofer Goofer ${solved ? "✅" : "❌"}`);
  shareDataArr.push(buildDate(puzzleDay));
  shareDataArr.push(buildHintStr(numHintsUsed));
  if (solved && timerEnabled && puzzleTimeSec) {
    shareDataArr.push(buildTimeStr(puzzleTimeSec, topPercent));
  }
  shareDataArr.push(getTwoferLink());
  return shareDataArr.join("\n");
}

function getTopPercent(puzzleTimeSec, percentiles) {
  if (!puzzleTimeSec || !percentiles) {
    return null;
  }

  for (const percentile of percentiles) {
    if (puzzleTimeSec <= percentile.value) {
      return percentile.percentile;
    }
  }

  return null;
}

export default function SuccessModal({
  puzzleState,
  open,
  setOpen,
  setHistoryModalOpen,
  answer,
  imageUrl,
  puzzleNumber,
  initialsHintUsed,
  syllablesHintUsed,
  moreDirectClueUsed,
  blurredDepictionUsed,
  historicalState,
  puzzleTimeSec,
  timerEnabled,
  randomTwoferId,
  mostRecentPuzzleId,
  setId,
  viewportHeight,
  scaleFactor,
  experiments,
  puzzles,
  setCalendarModalOpenWithTracking,
  isTodayPuzzle,
  currentDate,
  puzzleDay
}) {

  const hintBooleans = [
    syllablesHintUsed,
    blurredDepictionUsed,
    initialsHintUsed,
    moreDirectClueUsed,
  ];
  const [percentiles, setPercentiles] = useState([]);
  const fetcher = (...args) => fetch(...args).then((res) => res.json().then((data) => {
    setPercentiles(data)
  }));
  const { data, error } = useSWR(`/api/percentiles/${mostRecentPuzzleId}`, fetcher);

  const topPercent = getTopPercent(puzzleTimeSec, percentiles);
  const solved = puzzleState === PUZZLE_STATES.SOLVED;
  const numHintsUsed = hintBooleans.filter(Boolean).length;

  const thumbprint = buildThumbprint(
    solved,
    puzzleNumber,
    numHintsUsed,
    timerEnabled,
    puzzleTimeSec,
    topPercent,
    puzzleDay,
  );

  const shareToClipboard = () => {
    toast("Copied to clipboard", {
      icon: "📋",
      duration: 2000,
    });
    navigator.clipboard.writeText(thumbprint);
  };
  const shareStats = async () => {
    let errorMsg;
    if (navigator.share) {
      try {
        navigator.share({ text: thumbprint });
      } catch (err) {
        errorMsg = `${err.name} - ${err.cause}`;
        shareToClipboard();
      }
    } else {
      shareToClipboard();
    }
    trackClick("share_button", { error: errorMsg });
  };
  const title = solved ? "Oh yes, great guess!" : "Not quite, not right";
 
  let playOrHistoryLink;
  if (randomTwoferId) {
    playOrHistoryLink = (
      <button
        autoFocus
        className="gtm-results-play-another-button cursor-pointer bg-hot-pink hover:bg-[#CC0058] text-white px-4 py-2 rounded-full shadow-sm  font-bold flex gap-x-1 items-center justify-center text-[18px] border-[2px] border-[#FF006E]"
        onClick={async () => {
          await trackClick("play_random");
          setOpen(false);
          setTimeout(() => {
            setId(randomTwoferId);
            const p = puzzles.find(
              (puzzle) => puzzle.id == randomTwoferId
            );
            Router.push(`/daily/${p?.puzzle_number}`);
          }, 200);
        }}
      >
        Play Another 
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    );
  } else {
    playOrHistoryLink = (
      <div
        className="text-blue-700 hover:text-blue-500 cursor-pointer text-sky-blue"
        onClick={() => {
          setOpen(false);
          setHistoryModalOpen(true);
        }}
      >
        View history →
      </div>
    );
  }

  let topPercentStr = "";
  if (topPercent) {
    topPercentStr = ` (Top ${topPercent}%)`;
  }

  let timerDiv;
  if (solved && timerEnabled && puzzleTimeSec) {
    timerDiv = (
      <div className="font-semibold text-sm text-gray-700 dark:text-gray-300 text-center flex items-center align-center justify-center mt-1">
        {`⏱️  ${secondsToStr(puzzleTimeSec)}${topPercentStr}`}
      </div>
    );
  }

  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(null);
  const [modalScaleFactor, setModalScaleFactor] = useState(1);

  // Change the useEffect hook to the following
  const [isResizing, setIsResizing] = useState(true);

  const afterEnterResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      setModalScaleFactor(
        (viewportHeight * 0.95) / containerRef.current.offsetHeight
      );
      setIsResizing(false);
    }
  };

  const shouldScale = viewportHeight < 500 && containerHeight > viewportHeight;

  // get today's date in YYYY-MM-DD format
  const today = format(new Date(), 'yyyy-MM-dd', { timeZone: 'America/New_York' });
  const todaysPuzzle = puzzles.find((puzzle) => puzzle.day === today);
  const isModalShowingTodaysPuzzle =
    todaysPuzzle && todaysPuzzle.puzzle_number === puzzleNumber;

  // Find next and prior playable puzzle ids
  // Get the current puzzleNumber's date
  const puzzleFromPuzzleNumber = puzzles.find(
    (puzzle) => puzzle.puzzle_number === puzzleNumber
  );
  const dateOfPuzzleFromPuzzleNumber =
    puzzleFromPuzzleNumber && puzzleFromPuzzleNumber.day;

  // take the puzzles array of objects. For each object, add an "solved" property.
  // if the puzzle id is in historicalState and the puzzle.puzzleState == PUZZLE_STATES.SOLVED or PUZZLE_STATES.SOLVED_WITH_HINTS, then set solved to true.
  // otherwise set solved to false.
  const allPuzzlesWithUserSolved = puzzles.map((puzzle) => {
    const historicalPuzzleState = historicalState && historicalState[puzzle.id];
    const solved =
      historicalPuzzleState &&
      (historicalPuzzleState.puzzleState === PUZZLE_STATES.SOLVED ||
        historicalPuzzleState.puzzleState === PUZZLE_STATES.GAVE_UP);
    return {
      ...puzzle,
      solved,
    };
  });

  // sort by desscending date -- i.e. from oldest to most recent -- so .find works later
  const allPuzzlesWithUserSolvedSortedDesc = [...allPuzzlesWithUserSolved].sort(
    (a, b) => {
      if (a.day !== b.day) {
        return new Date(b.day) - new Date(a.day);
      }
    }
  );

  // find the allPuzzlesWithUserSolvedSortedDesc puzzle where solved is false and it's the closest previous day of dateOfPuzzleFromPuzzleNumber
  const priorPlayablePuzzle = allPuzzlesWithUserSolvedSortedDesc.find(
    (puzzle) => !puzzle.solved && puzzle.day < dateOfPuzzleFromPuzzleNumber
  );
  const priorPlayablePuzzleId = priorPlayablePuzzle?.id ?? null;

  // sort by desscending date -- i.e. from oldest to most recent -- so .find works later
  const allPuzzlesWithUserSolvedSortedAsc = [...allPuzzlesWithUserSolved].sort(
    (a, b) => {
      if (a.day !== b.day) {
        return new Date(a.day) - new Date(b.day);
      }
    }
  );

  // find the allPuzzlesWithUserSolvedSortedAsc puzzle where solved is false and it's the closest previous day of dateOfPuzzleFromPuzzleNumber
  const nextPlayablePuzzle = allPuzzlesWithUserSolvedSortedAsc.find(
    (puzzle) => !puzzle.solved && puzzle.day > dateOfPuzzleFromPuzzleNumber
  );
  const nextPlayablePuzzleId = nextPlayablePuzzle?.id ?? null;

  // if every puzzle is solved, then noPlayablePuzzlesLeft will be null
  const noPlayablePuzzlesLeft = !priorPlayablePuzzleId && !nextPlayablePuzzleId;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity dark:bg-black dark:bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0">
          <div className="fixed z-10 inset-0">
            <div
              className={`flex items-center sm:items-start sm:pt-6 justify-center min-h-full p-2 text-center sm:p-0 ${isResizing ? "opacity-0" : "opacity-100"
                }`}
              ref={containerRef}
              style={{
                transform: shouldScale ? `scale(${modalScaleFactor})` : ``,
                transformOrigin: shouldScale ? `top` : ``,
                top: shouldScale ? `20px` : ``,
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                afterEnter={afterEnterResize}
              >
                <Dialog.Panel className="relative bg-white dark:bg-gray-900 rounded-lg pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 w-80 sm:max-w-sm">
                  <div
                    className="absolute top-4 right-4 cursor-pointer z-30"
                    onClick={() => setOpen(false)}
                  >
                    <XMarkIcon className="h-5 w-5 text-white"></XMarkIcon>
                  </div>
                  <div>
                    <div>
                      <Dialog.Title
                        as="h3"
                        className="absolute top-4 text-center text-xl font-semibold text-white w-full z-20"
                      >
                        {answer.toUpperCase()}
                      </Dialog.Title>
                      <div className="relative w-full h-full">
                        <div className="image-gradient w-full h-32 z-10 absolute"></div>
                        <div className="rounded-full h-7 w-7 bg-white dark:bg-gray-900 dark:text-white text-center absolute z-50 top-3.5 left-3.5 text-sm font-semibold text-gray-900 flex items-center justify-center">
                          {puzzleNumber}
                        </div>
                        <Image
                          className="rounded-t-lg w-full h-auto"
                          src={imageUrl}
                          alt={answer}
                          width="0"
                          height="0"
                          sizes="100vw"
                          objectFit="cover"
                          loading="eager"
                        />
                      <div className="image-gradient w-full h-[100px] rotate-180 z-10 absolute bottom-0 left-0"></div>
                      <div className="absolute bottom-1 right-1 z-50 flex px-4 justify-end pt-1.5 pb-1">
                        <span className="text-xs text-white">Image generated with AI</span>
                      </div>
                      </div>
                      <div className="px-4 sm:px-6">
                        <div className="mt-3 text-xl font-semibold text-gray-700 dark:text-gray-200 text-center flex items-center align-center justify-center">
                          {title}
                        </div>
                        {timerDiv}
                        <div className="mt-2 sm:mt-4 flex justify-between items-center">
                          {
                            playOrHistoryLink
                          }
                          <button
                            type="button"
                            className="gtm-results-share-button inline-flex gap-1 justify-center items-center bg-transparent hover:bg-sky-500 hover:text-white border border-sky-500 text-sky-500 rounded-full shadow-sm px-4 py-2 text-[18px] font-bold cursor-pointer focus-visible:outline-0"
                            onClick={shareStats}
                          >
                            <span>Share</span>
                            <ShareIcon className="w-5 h-5"></ShareIcon>
                          </button>
                          
                        </div>
                        <div className="mt-2 sm:mt-4 text-center mb-2">
                          <div className="flex justify-between text-sm mb-2 font-semibold">
                            <div className="text-base dark:text-gray-200">
                              Lifetime Stats
                            </div>
                          </div>
                          <Stats
                            historicalState={historicalState}
                            mostRecentPuzzleId={mostRecentPuzzleId}
                            puzzles={puzzles}
                            isTodayPuzzle={isTodayPuzzle}
                            currentDate={currentDate}
                          ></Stats>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
