import Head from "next/head";
import dynamic from 'next/dynamic';
const MwBigBarNav = dynamic(() => import('../components/mw-big-bar-nav'), {
  ssr: false,
});

export default function Layout({ 
  children 
}) {
  return (
    <>
      <Head>
        <title>
          Twofer Goofer, a daily rhyming word game | Merriam-Webster
        </title>
        <meta
          name="og:image"
          content="https://rfwouezcbvpmywbsuans.supabase.co/storage/v1/object/public/twofer-goofer-images/TG-new.png"
        />
        <meta
          name="og:description"
          content="Twofer Goofers are pairs of rhyming words that form a nonsensical phrase. Use the clue to find the rhyme and solve the puzzle."
        />
        <meta
          name="og:title"
          content="Twofer Goofer, a daily rhyming word game | Merriam-Webster"
        />
        <meta
          name="description"
          content="Twofer Goofers are pairs of rhyming words that form a nonsensical phrase. Use the clue to find the rhyme and solve the puzzle."
        />
        <meta
          name="twitter:title"
          content="Twofer Goofer, a daily rhyming word game | Merriam-Webster"
        />
        <meta
          name="twitter:description"
          content="Twofer Goofers are pairs of rhyming words that form a nonsensical phrase. Use the clue to find the rhyme and solve the puzzle."
        />
        <meta
          name="twitter:image"
          content="https://rfwouezcbvpmywbsuans.supabase.co/storage/v1/object/public/twofer-goofer-images/Summary%20image.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://rfwouezcbvpmywbsuans.supabase.co/storage/v1/object/public/twofer-goofer-images/TG-new.png"
        />
      </Head>
      {/* Debugging panel helpful when looking on mobile */}
      {/* <div className="inset-x-0 absolute z-50 bg-black text-white bottom-0">
        <p>Scale factor:{scaleFactor}</p>
        <p>Viewport height:{viewportHeight}</p>
      </div> */}

      <div className="twofer-main h-full h-[100dvh] w-full absolute left-[0] top-[0] z-[2147483646] flex flex-col overflow-x-hidden">
        <div
          id="header-placement"
          className="header-banner-nav ad-container w-full relative min-h-[94px] h-[94px] md:block hidden bg-black"
        ></div>

        <div className="w-full bg-[#0f3850] h-[58px] flex items-center pt-2 pl-1">
          <MwBigBarNav />
        </div>
        <div className="relative overflow-hidden">
          <div
            id="sidebar"
            className="sidebar absolute hidden md:block h-auto ad-container"
          >
            <div className="sidebar-ad-1"></div>
            <div className="sidebar-ad-2"></div>
          </div>
        </div>
       

          {children}
       
        <div
          id="footer-placement"
          className="footer-placement ad-container flex-none self-end w-full relative md:hidden min-h-[78px] h-[78px] bg-black"
        ></div>
      </div>
    </>
  );
}
