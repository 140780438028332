import * as React from "react";
import { SPECIAL_KEYS } from "./keyboard";
import { PUZZLE_STATES } from "./puzzle";

const ENTER_KEYCODE = 13;
const DELETE_KEYCODE = 8;
const SPACE_KEYCODE = 32;

const SIZE_CLASSES = {
  default: "w-12 h-12 text-2xl",
  medium: "w-10 h-10 text-xl rounded-md",
  small: "w-9 h-9 text-lg rounded-md",
  tiny: "w-[30px] h-[30px] text-lg rounded-md",
};

const getPreviousInput = (prevContainer) => {
  if (!prevContainer) {
    return;
  }
  let currentChild = prevContainer.lastChild;
  while (currentChild) {
    if (currentChild.tagName == "INPUT") {
      return currentChild;
    }
    currentChild = currentChild.previousSibling;
  }
  return currentChild;
};

class WordInput extends React.Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  handleKeypress(evt) {
    if (evt.keyCode == SPACE_KEYCODE) {
      evt.preventDefault();
      return;
    }
    if (evt.keyCode == ENTER_KEYCODE) {
      if (!this.isComplete()) {
        return;
      }
      evt.preventDefault();
      this.props.handleSubmit();
      return;
    }
    if (evt.keyCode !== DELETE_KEYCODE) {
      return;
    }
    if (evt.keyCode == DELETE_KEYCODE) {          
      this.handleDeletePress();
      this.pickFocus();
      return;
    }
    evt.preventDefault();
    const currentElement = evt.currentTarget;
    if (currentElement.value !== "") {
      currentElement.value = "";
      this.handleOnChange();
      return;
    }
    const wordContainer = currentElement.parentElement;
    const prevWordContainer = wordContainer.previousSibling;
    let previousSibling = currentElement.previousSibling;
    if (!previousSibling || previousSibling.tagName != "INPUT") {
      previousSibling = getPreviousInput(prevWordContainer);
    }
    if (!previousSibling) {
      return;
    }
    previousSibling.value = "";
    this.handleOnChange();
  }

  setPuzzleState() {
    if (this.isComplete()) {
      this.props.setPuzzleState(PUZZLE_STATES.READY_TO_SUBMIT);
    } else {
      this.props.setPuzzleState(PUZZLE_STATES.WAITING_FOR_INPUT);
    }
  }

  handleKeyboardEvent(key) {
    if (key === SPECIAL_KEYS.BACKSPACE) {
      this.handleDeletePress();
    } else if (key === SPECIAL_KEYS.ENTER) {
      this.handleEnterPress();
    } else {
      this.handleAddLetter(key);
    }
    this.pickFocus();
  }

  handleDeletePress() {
    const inputs = this.getInputs();
    let priorInput;
    for (const input of inputs) {
      if (input.value === "") {
        if (priorInput) {
          priorInput.value = "";
          this.setPuzzleState();
        }
        return;
      }
      priorInput = input;
    }
    priorInput.value = "";
    this.setPuzzleState();
  }

  handleEnterPress() {
    if (!this.isComplete()) {
      return;
    }
    this.props.handleSubmit();
  }

  handleAddLetter(ltr) {
    const inputs = this.getInputs();
    for (const input of inputs) {
      if (input.value == "") {
        input.value = ltr;
        this.setPuzzleState();
        return;
      }
    }
  }

  handleClick(evt) {
    evt.preventDefault();
    this.pickFocus();
    const currentElement = evt.currentTarget;
    if (currentElement.value !== "") {
      currentElement.value = "";
      this.handleOnChange();
      return;
    }

  }

  handleOnChange() {
    this.pickFocus();
    this.setPuzzleState();
  }

  useCustomKeyboard() {
    if (this.props.disableCustomKeyboard) {
      return false;
    }
    return this.props.isMobile || this.isMobile();
  }

  pickFocus() {
    const lastInput = this.getLastInput();
    if (this.useCustomKeyboard()) {
      for (const input of this.getInputs()) {
        input.classList.remove("border-blue-300");
        input.classList.remove("border-2");
      }

      lastInput.classList.add("border-blue-300");
      lastInput.classList.add("border-2");
    } else {
      lastInput.focus();
    }
  }

  isMobile() {
    return (
      typeof window !== "undefined" &&
      window.matchMedia("(max-width: 639px)").matches
    );
  }

  getLastInput() {
    const inputs = this.getInputs();
    for (const input of inputs) {
      if (input.value == "") {
        return input;
      }
    }
    return inputs[inputs.length - 1];
  }

  getInputs(container = null) {
    container = container || this.containerRef.current;
    const inputs = container.getElementsByTagName("input");
    return Array.prototype.slice.call(inputs);
  }

  getAnswerBoxes(container = null) {
    container = container || this.containerRef.current;
    const inputs = container.getElementsByTagName("span");
    return Array.prototype.slice.call(inputs);
  }

  isDisabled() {
    return (
      (this.props.puzzleState != PUZZLE_STATES.WAITING_FOR_INPUT &&
        this.props.puzzleState != PUZZLE_STATES.READY_TO_SUBMIT) ||
      this.useCustomKeyboard()
    );
  }

  setDisabled() {
    const isDisabled = this.isDisabled();
    for (const input of this.getInputs()) {
      input.disabled = isDisabled;
    }
  }

  componentDidMount() {
    this.pickFocus();
    this.setDisabled();
  }

  createInput(wordIdx, _, idx) {
    const maxWordLength = Math.max(...this.props.wordLengths);
    let currentSizeClasses;
    if (maxWordLength <= 6) {
      currentSizeClasses = SIZE_CLASSES.medium;
    } else if (maxWordLength >= 8) {
      currentSizeClasses = SIZE_CLASSES.tiny;
    } else if (maxWordLength == 7) {
      currentSizeClasses = SIZE_CLASSES.small;
    }
    const isFirstLetter = idx == 0;
    const showLetter = isFirstLetter && this.props.showFirstInitials;
    const placeholder = showLetter ? this.props.firstLetters[wordIdx] : "";
    let stylingClasses;
    switch (this.props.puzzleState) {
      case PUZZLE_STATES.SOLVED:
        stylingClasses = "text-white bg-real-green border-green-500";
        break;
      case PUZZLE_STATES.GAVE_UP:
        stylingClasses =
          "text-real-red bg-red-100 border-red-300 dark:border-red-700 dark:bg-red-900/20 dark:text-red-500";
        break;
      case PUZZLE_STATES.WRONG_ANSWER:
        stylingClasses =
          "text-real-red bg-red-100 border-transparent dark:bg-red-900/20 dark:text-red-500";
        break;
      default:
        stylingClasses =
          "border-gray-300 dark:border-gray-700 dark:bg-gray-800 disabled:text-black disabled:opacity-100 dark:text-white dark:disabled:text-white";
        break;
    }

    let animationStyle = {};
    let additionalClassName = "";

    if (this.props.puzzleState === PUZZLE_STATES.GAVE_UP) {
      animationStyle = {
        animationName: "popAndJiggle",
        animationDuration: "1.2s",
        animationTimingFunction: "ease-out",
      };
    } else if (this.props.puzzleState === PUZZLE_STATES.SOLVED) {
      additionalClassName = "solved-animation";
      animationStyle = {
        animationDelay: `${idx * 0.08}s`,
      };
    }

    return (
      <div 
        className="relative"
        key={'div-'+idx}
      >      
        <input
          key={idx}
          type="text"
          id={`${this.props.id}-${idx}`}
          placeholder={placeholder}
          onChange={this.handleOnChange.bind(this)}
          onKeyDown={this.handleKeypress.bind(this)}
          onClick={this.handleClick.bind(this)}
          maxLength={1}
          disabled={this.isDisabled()}
          autoComplete="off"
          autoCapitalize="none"
          className={`${stylingClasses} rounded-lg font-medium uppercase border border-2 p-1 ${currentSizeClasses} sm:w-14 sm:h-14 sm:text-3xl text-center focus-within:ring-transparent ${additionalClassName}`}
          style={{opacity: 1, ...animationStyle}}
        ></input>
        <span 
          key={'span-'+idx}
          className={"text-white absolute top-[0] left-[0]  " + `font-medium uppercase border-2 border border-transparent p-1 ${currentSizeClasses} ${stylingClasses} ${additionalClassName} sm:w-14 sm:h-14 sm:text-3xl text-center
          focus-within:ring-transparent
          `
          }
          style={{display: 'none', lineHeight: 1.35, ...animationStyle}}
        ></span>
      </div>
    );
  }

  getWordContainers() {
    if (!this.containerRef.current) {
      return [];
    }
    const wordContainers =
      this.containerRef.current.getElementsByClassName("word-container");
    return Array.prototype.slice.call(wordContainers);
  }

  getValue() {
    const words = this.getWordContainers().map((container) => {
      const inputs = this.getInputs(container);
      return inputs.map((input) => input.value.toUpperCase()).join("");
    });
    return words.join(" ");
  }

  resetInputs() {
    for (const input of this.getInputs()) {
      input.value = "";
      input.style.opacity = 1;
    }
    for (const input of this.getAnswerBoxes()) {
      input.textContent = "";
      input.style.display = 'none';
    }
    this.pickFocus();
  }

  showAnswer() {
    const answerArr = this.props.answer.replace(" ", "").split("");
    const answerArr2 = this.props.answer.replace(" ", "").split("");

    for (const input of this.getInputs()) {
      const currentLetter = answerArr.shift().toUpperCase();
      input.value = currentLetter;
      input.style.opacity = 0;

    }
    for (const input of this.getAnswerBoxes()) {
      const currentLetter = answerArr2.shift().toUpperCase();
      input.textContent = currentLetter;
      input.style.display = 'block';
    }
  }

  isComplete() {
    return this.getInputs().every((el) => el.value != "");
  }

  render() {
    const inputsArr = [];
    this.props.wordLengths.forEach((len, wordIdx) => {
      const inputs = [...Array(len)].map(this.createInput.bind(this, wordIdx));
      inputsArr.push(inputs);
    });
    const words = inputsArr.map((inputs, idx) => {
      {
        /* awu: the ternary adds left padding when syllables are shown so that the input stays centered*/
      }
      return (
        <div
          className={`flex sm:gap-2 gap-1 justify-center items-center word-container`}
          key={idx}
        >
          <div
            className={`${
              this.props.showSyllables ? "opacity-0" : "opacity-0"
            } sm:w-10 sm:h-10 w-6 h-6 rounded-full bg-gray-500 text-center flex justify-center align-center flex-col font-semibold sm:text-xl text-lg text-white`}
          >
            {this.props.numSyllables[idx]}
          </div>
          {inputs}
          <div
            className={`${
              this.props.showSyllables ? "" : "opacity-0"
            } sm:w-10 sm:h-10 w-6 h-6 rounded-full bg-gray-500 text-center flex justify-center align-center flex-col font-semibold sm:text-xl text-lg text-white`}
          >
            {this.props.numSyllables[idx]}
          </div>
        </div>
      );
    });

    return (
      <div
        className="justify-center flex flex-col w-full sm:gap-2 gap-1"
        ref={this.containerRef}
      >
        {words}
      </div>
    );
  }
}

export default WordInput;
