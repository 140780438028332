import {
  PUZZLE_STATES
} from "../components/puzzle";
import {
  getNextEasyTwoferId
} from "./experiment";
import {
  setPersistedPuzzleState,
  getTwoferGooferState,
  getUserId,
  getStreakData,
  storeStreakData,
} from "./local-storage";
import {
  getCurrentDomain,
  getReferrer
} from "./referrer";
import supabase from "./supabase";

export function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

function hashCode(str) {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function shuffle(array) {
  const seed = hashCode(getUserId());
  return shuffleWithSeed(array, seed);
}

function shuffleWithSeed(array, seed) {
  var m = array.length,
    t,
    i;

  while (m) {
    i = Math.floor(random(seed) * m--);
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

export function getPuzzleFromId(puzzles, id) {
  for (const puzzle of puzzles) {
    if (puzzle.id == id) {
      return puzzle;
    }
  }
  return puzzles[puzzles.length - 1];
}

export async function syncHistoricalState(user) {
  const state = getTwoferGooferState();
  let puzzleIdToPersist = null;
  let puzzleStateToPersist = null;
  for (const puzzleId of Object.keys(state)) {
    const puzzleState = state[puzzleId];
    if (puzzleState.savedInPuzzleResults) {
      continue;
    }
    puzzleIdToPersist = puzzleId;
    puzzleStateToPersist = puzzleState;
    break;
  }
  if (!puzzleStateToPersist) {
    return;
  }
  const numHintsUsed =
    puzzleStateToPersist.numHintsUsed || [
      puzzleStateToPersist.showBlurredDepiction,
      puzzleStateToPersist.showFirstInitials,
      puzzleStateToPersist.showMoreDirectClue,
      puzzleStateToPersist.showSyllables,
    ].filter(Boolean).length;
  await supabase.from("puzzle_results").insert([{
    puzzle_id: puzzleIdToPersist,
    user_id: user && user.id,
    puzzle_time_sec: puzzleStateToPersist.puzzleTimeSec,
    num_hints_used: numHintsUsed,
    solved: puzzleStateToPersist.puzzleState == PUZZLE_STATES.SOLVED,
    logged_out_user_id: getUserId(),
    referrer: getReferrer(),
    domain: getCurrentDomain(),
  }, ]);
  setPersistedPuzzleState(puzzleIdToPersist, puzzleStateToPersist);

  setTimeout(() => {
    syncHistoricalState(user);
  }, 1000);
}

export function checkAnswer(answer, submittedAnswer) {
  if (!answer) {
    return false;
  }
  const submittedAnswerArr = submittedAnswer.toUpperCase().split(" ").sort();
  const answerArr = answer.toUpperCase().split(" ").sort();
  return arrayEquals(submittedAnswerArr, answerArr);
};

export function pickRandomTwofer(
  puzzles,
  historicalState,
  mostRecentPuzzleId,
  currentId,
) {
  const nextEasyId = getNextEasyTwoferId(currentId);
  if (nextEasyId) {
    return nextEasyId;
  }

  const playedIds = [currentId];
  for (const puzzleId of Object.keys(historicalState)) {
    const puzzle = historicalState[puzzleId];
    if (isPuzzleComplete(puzzle.puzzleState)) {
      playedIds.push(Number(puzzleId));
    }
  }
  const candidateIds = [];
  for (const puzzle of puzzles) {
    const id = Number(puzzle.id);
    if (!isNaN(id) && id != mostRecentPuzzleId && !playedIds.includes(id)) {
      candidateIds.push(id);
    }
  }

  // Return the most recent not played puzzle id
  if (candidateIds.length > 0 ) return candidateIds[candidateIds.length - 1];

  // If there are no other options, return the today puzzle id.
  return mostRecentPuzzleId;
}

export function formatSec(sec) {
  const min = Math.floor(sec / 60);
  const remainderSec = sec - min * 60;
  let secStr;
  if (remainderSec < 10) {
    secStr = `0${remainderSec}`;
  } else {
    secStr = remainderSec;
  }
  return `${min}:${secStr}`;
}

export function isPuzzleComplete(puzzleState) {
  return [PUZZLE_STATES.SOLVED, PUZZLE_STATES.GAVE_UP].includes(puzzleState);
}

export function calculateStreak(isTodayPuzzle, puzzleId, historicalState, puzzles, isPuzzleSolved = false ) {
  const storedStreakData = getStreakData();
  let shouldResetStreak = true;
  let usedForStreakCalculation = isTodayPuzzle;

  // Check if today puzzle is today puzzle
  //     Check if yesterday puzzle was played, if solved add to the streak, if not reset to 1 
  if (storedStreakData.currentStreak) {
    if (isTodayPuzzle) {
      const todayPuzzleIndex = puzzles.findIndex(p => p.id === puzzleId);

      // If found index is > 0, it means that it found a puzzle and there is a older puzzle to check.
      if (todayPuzzleIndex > 0) {
        const yesterdayPuzzle = puzzles[todayPuzzleIndex - 1];
        const playedYesterdayPuzzle = historicalState[yesterdayPuzzle.id];
        // Look for yesterdayPuzzle in current historical state and check if it is solved
        if (playedYesterdayPuzzle?.puzzleState === PUZZLE_STATES.SOLVED) {
          shouldResetStreak = false;
        }
      } 
    }
  }

  if (usedForStreakCalculation) { 
    if (!isPuzzleSolved || shouldResetStreak) {
      storedStreakData.currentStreak = 0;
    }
    // Today's puzzle has been solved
    if (isPuzzleSolved) {
      storedStreakData.currentStreak += 1;
    }
  }

  storedStreakData.maxStreak = Math.max(storedStreakData.currentStreak, storedStreakData.maxStreak || 0 );

  storeStreakData(storedStreakData.currentStreak, storedStreakData.maxStreak);
}