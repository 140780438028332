import React, { useEffect, useState } from "react";
import Router from "next/router";
import Image from "next/image";
import { ChevronRightIcon, QuestionMarkCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  CalendarDaysIcon,
  CheckCircleIcon,
  ChevronLeftIcon,
  Cog8ToothIcon,
} from "@heroicons/react/24/solid";
import WordInput from "../components/word-input.jsx";
import Hints from "../components/hints";
import SuccessModal from "../components/success-modal.jsx";
import supabase from "../util/supabase.js";
import HistoryModal from "../components/history-modal.jsx";
import CalendarModal from "../components/calendar-modal.jsx";
import {
  getCustomKeyboardDisabled,
  getDarkModeForced,
  getPersistedPuzzleState,
  getStoredPuzzleTimeSec,
  getTimerEnabled,
  getTwoferGooferState,
  getUserId,
  haveInstructionsBeenDismissed,
  setInstructionsModalDismissed,
  setPersistedPuzzleState,
  storeCustomKeyboardDisabled,
  storeDarkModeForced,
  storeTimerEnabled,
} from "../util/local-storage.js";
import { trackAnswerSubmitted, trackClick } from "../util/tracking.js";
import Keyboard from "../components/keyboard.jsx";
import { getReferrer } from "../util/referrer.js";
import {
  calculateStreak,
  checkAnswer,
  isPuzzleComplete,
  pickRandomTwofer,
  syncHistoricalState,
} from "../util/puzzle-util.js";
import BasePage from "./base-page.jsx";
import { usePageState } from "../util/shared.js";
import Timer from "./timer.jsx";
import MockIOSKeyboard from "./mock-ios-keyboard.jsx";
import SmartErrors from "./smart-errors.jsx";
import GTMUtils from "../util/GTM.utils.js";

export const PUZZLE_STATES = {
  WAITING_FOR_INPUT: "WAITING_FOR_INPUT",
  READY_TO_SUBMIT: "READY_TO_SUBMIT",
  WRONG_ANSWER: "WRONG_ANSWER",
  SOLVED: "SOLVED",
  GAVE_UP: "GAVE_UP",
};

export default function Puzzle({
  puzzles,
  dbg,
  isMobile,
  mostRecentPuzzleId,
  id,
  setId,
  puzzle,
  viewportHeight,
  scaleFactor,
  experiments,
  isTodayPuzzle,
  currentDate,
}) {
  const {
    prompt,
    answer,
    alternate_answer,
    alternate_prompt,
    first_word_num_syllables,
    second_word_num_syllables,
    image,
    puzzle_number,
    blurred_image,
  } = puzzle;
  const inputsRef = React.createRef();
  const [user, setUser] = useState(null);
  const [showSyllables, setShowSyllables] = useState(false);
  const [showBlurredDepiction, setShowBlurredDepiction] = useState(false);
  const [showFirstInitials, setShowFirstInitials] = useState(false);
  const [showMoreDirectClue, setShowMoreDirectClue] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [puzzleState, setPuzzleState] = useState(
    PUZZLE_STATES.WAITING_FOR_INPUT
  );

  const [historicalState, setHistoricalState] = useState(null);
  const [randomTwoferId, setRandomTwoferId] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [calendarScrollPosition, setCalendarScrollPosition] = useState(0);
  const [completedPuzzleTimeSec, setCompletedPuzzleTimeSec] = useState(
    getStoredPuzzleTimeSec(id)
  );
  const [mostRecentlySubmittedAnswer, setMostRecentlySubmittedAnswer] =
    useState(null);

  const SOLVED_PUZZLE_ANIMATION_DURATION = 1500;

  const basePageState = usePageState();
  const {
    instructionsModalOpen,
    setInstructionsOpen,
    setInstructionsOpenWithTracking,
    setInstructionsOpenHandler,
    settingsModalOpen,
    setSettingsModalOpenWithTracking,
    timerEnabled,
    setTimerEnabled,
    setTimerEnabledWithTracking,
    disableCustomKeyboard,
    setDisableCustomKeyboard,
    setDisableCustomKeyboardWithTracking,
    darkModeForced,
    setDarkModeForced,
    menuOpen,
    setMenuOpen,
  } = basePageState;

  // For any puzzles that haven't been saved to the
  // TG database, save them.  Preparation for authenticated
  // experience, no current impact.
  useEffect(() => {
    syncHistoricalState(user);
  }, [user]);

  // Makes sure we're in dark mode if we need to be;
  // either set based on OS settings or user setting in app.
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    if (darkModeForced != null) {
      storeDarkModeForced(darkModeForced);
    }
    if (darkModeForced || darkModeMediaQuery.matches) {
      setIsDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      setIsDarkMode(false);
      document.documentElement.classList.remove("dark");
    }
  }, [darkModeForced]);

  const puzzleComplete = isPuzzleComplete(puzzleState);
  const showSuccessModal = puzzleComplete && successModalOpen;

  const setHistoryModalOpenWithTracking = async (val) => {
    setHistoryModalOpen(val);
    if (val) {
      await trackClick("history_modal");
    }
  };

  const setCalendarModalOpenWithTracking = async (val) => {
    setCalendarModalOpen(val);
    if (val) {
      await trackClick("calendar_modal");
    }
  };

  const setSuccessModalOpenWithTracking = async (val) => {
    setSuccessModalOpen(val);
    if (val) {
      await trackClick("success_modal");
    }
  };

  // Loads state from local storage and then makes sure app
  // state is in sync with what's in local storage.  So for example,
  // if a user has started playing a puzzle, views some hints and then
  // reloads the page, the puzzles will re-render with the hints they've
  // already revealed.
  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);

    const storedShowSyllables = currentPuzzleState["showSyllables"] || false;
    setShowSyllables(storedShowSyllables);

    const storedShowBlurredDepiction =
      currentPuzzleState["showBlurredDepiction"] || false;
    setShowBlurredDepiction(storedShowBlurredDepiction);

    const storedShowFirstInitials =
      currentPuzzleState["showFirstInitials"] || false;
    setShowFirstInitials(storedShowFirstInitials);

    const storedShowMoreDirectClue =
      currentPuzzleState["showMoreDirectClue"] || false;
    setShowMoreDirectClue(storedShowMoreDirectClue);

    const storedPuzzleState =
      currentPuzzleState["puzzleState"] || PUZZLE_STATES.WAITING_FOR_INPUT;
    setPuzzleState(storedPuzzleState);

    const storedTimerEnabled = getTimerEnabled();
    if (storedTimerEnabled == undefined) {
      setTimerEnabled(true);
    } else {
      setTimerEnabled(storedTimerEnabled);
    }

    const storedCustomKeyboardDisabled = getCustomKeyboardDisabled();
    if (storedCustomKeyboardDisabled == undefined) {
      setDisableCustomKeyboard(true);
    } else {
      setDisableCustomKeyboard(storedCustomKeyboardDisabled);
    }

    const storedDarkModeForced = getDarkModeForced();
    setDarkModeForced(storedDarkModeForced);

    if (isPuzzleComplete(storedPuzzleState)) {
      inputsRef.current.showAnswer();
      setSuccessModalOpen(true);
      setInstructionsModalDismissed();
    }
    setInstructionsOpen(!haveInstructionsBeenDismissed());
  }, [
    id,
    setDarkModeForced,
    setDisableCustomKeyboard,
    setInstructionsOpen,
    setTimerEnabled,
  ]); // TOOD(sam): inputRef is technically a dep here, but we don't wanna rerun this effect when it changes, fix.

  // Resets the word input if we update the puzzle id; allows us
  // to change which puzzle is being rendered without reloading the page.
  
  /*
  useEffect(() => {
    const wordInput = inputsRef.current;
    if (!wordInput) {
      return;
    }
    if (id != mostRecentPuzzleId && id != wordInput.id) {
      resetPuzzle(wordInput);
    }
  }, [id, mostRecentPuzzleId]);
*/

  // Saves the current puzzle to local storage.
  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["answer"] = answer;
    currentPuzzleState["alternate_answer"] = alternate_answer;
    currentPuzzleState["prompt"] = prompt;
    currentPuzzleState["image"] = image;
    currentPuzzleState["puzzle_number"] = puzzle_number;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, answer, alternate_answer, prompt, image, puzzle_number]);

  // Picks a random puzzle id that we use if the user clicks
  // "play random twofer".
  useEffect(() => {
    const fullState = getTwoferGooferState();
    setHistoricalState(fullState);
    const randomId = pickRandomTwofer(
      puzzles,
      fullState,
      mostRecentPuzzleId,
      id
    );
    setRandomTwoferId(randomId);
  }, [mostRecentPuzzleId, puzzles, id]);

  // These following effects keep the persisted puzzle state in sync
  // with any user actions; so if the user clicks "showSyllables" in the UI,
  // then the showSyllables variable will update, and we'll run this effect
  // persisting it to localStorage.
  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["showSyllables"] = showSyllables;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, showSyllables]);

  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["showBlurredDepiction"] = showBlurredDepiction;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, showBlurredDepiction]);

  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["showFirstInitials"] = showFirstInitials;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, showFirstInitials]);

  useEffect(() => {
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["showMoreDirectClue"] = showMoreDirectClue;
    setPersistedPuzzleState(id, currentPuzzleState);
  }, [id, showMoreDirectClue]);

  useEffect(() => {
    storeTimerEnabled(timerEnabled);
  }, [timerEnabled]);

  useEffect(() => {
    storeCustomKeyboardDisabled(disableCustomKeyboard);
  }, [disableCustomKeyboard]);

  // If we reach a terminal state for this puzzle (the user
  // either solves the puzzle or gives up), we persist the puzzleState
  // to localStorage.
  useEffect(() => {
    if (
      puzzleState != PUZZLE_STATES.GAVE_UP &&
      puzzleState != PUZZLE_STATES.SOLVED
    ) {
      return;
    }
    const currentPuzzleState = getPersistedPuzzleState(id);
    currentPuzzleState["puzzleState"] = puzzleState;
    setPersistedPuzzleState(id, currentPuzzleState);
    const fullState = getTwoferGooferState();
    setHistoricalState(fullState);
  }, [id, puzzleState]);

  const updateUser = () => {
    const user = supabase.auth.getUser();
    setUser(user);
  };

  useEffect(updateUser, []);

  const blurredDepictionSetter = () => {
    setShowBlurredDepiction(true);
  };

  const hintState = {
    "number-syllables": {
      used: showSyllables,
      setter: setShowSyllables,
    },
    "blurred-depiction": {
      used: showBlurredDepiction,
      setter: blurredDepictionSetter,
    },
    "first-initials": {
      used: showFirstInitials,
      setter: setShowFirstInitials,
    },
    "more-direct-clue": {
      used: showMoreDirectClue,
      setter: setShowMoreDirectClue,
    },
  };
  const numHintsAvailable = Object.values(hintState)
    .map((el) => !el.used)
    .filter(Boolean).length;
  const firstLetters = answer.split(" ").map((word) => word[0].toUpperCase());
  const numSyllables = [first_word_num_syllables, second_word_num_syllables];
  const wordLengths = answer.split(" ").map((word) => word.length);

  const getNumHintsUsed = () => {
    return [
      showSyllables,
      showBlurredDepiction,
      showFirstInitials,
      showMoreDirectClue,
    ].filter(Boolean).length;
  };

  const persistSubmission = async (solved) => {
    await supabase.from("puzzle_results").insert([
      {
        puzzle_id: id,
        user_id: user && user.id,
        puzzle_time_sec: getStoredPuzzleTimeSec(id),
        num_hints_used: getNumHintsUsed(),
        solved: solved,
        logged_out_user_id: getUserId(),
        referrer: getReferrer(),
      },
    ]);
  };

  const giveUp = () => {
    setMostRecentlySubmittedAnswer(null);
    setCompletedPuzzleTimeSec(getStoredPuzzleTimeSec(id));
    
    inputsRef.current.showAnswer();
    
    setPuzzleState(PUZZLE_STATES.GAVE_UP);
    
    calculateStreak(isTodayPuzzle, id, historicalState, puzzles, false );
    
    // delay after 1.5 seconds (to wait for GAVE UP animation to finish)
    setTimeout(() => {
      setSuccessModalOpen(true);
    }, SOLVED_PUZZLE_ANIMATION_DURATION);
    persistSubmission(false);
    trackClick("give_up");
  };
  const resetPuzzle = (wordInput) => {    
    setPuzzleState(PUZZLE_STATES.WAITING_FOR_INPUT);
    wordInput.resetInputs();
    setTimeout( () => {wordInput.resetInputs(), 150});
  };
  const handleTryAgainClick = () => {
    if (resetPuzzleTimerId) {
      clearTimeout(resetPuzzleTimerId);
      resetPuzzleTimerId = null;
    }
    resetPuzzle(inputsRef.current);
  };

  let resetPuzzleTimerId;
  const handleSubmit = async () => {
    const submittedAnswer = inputsRef.current.getValue();
    const isCorrectAnswer =
      checkAnswer(answer, submittedAnswer) ||
      checkAnswer(alternate_answer, submittedAnswer);
    setMostRecentlySubmittedAnswer(submittedAnswer);

    if (isCorrectAnswer) {
      const wordInput = inputsRef.current;
      wordInput.showAnswer();

      setPuzzleState(PUZZLE_STATES.SOLVED);
      // delay after 1.5 seconds (to wait for SOLVED animation to finish)
      setTimeout(() => {
        setSuccessModalOpen(true);
      }, SOLVED_PUZZLE_ANIMATION_DURATION);
      setCompletedPuzzleTimeSec(getStoredPuzzleTimeSec(id));
      persistSubmission(true);
      // Adjust streak
      calculateStreak(isTodayPuzzle, id, historicalState, puzzles, true );
 
    } else {
      setPuzzleState(PUZZLE_STATES.WRONG_ANSWER);
      const wordInput = inputsRef.current;
      
      resetPuzzleTimerId = setTimeout(() => {
        resetPuzzle(wordInput);
      }, 1800);
    }
    const answerSubmittedData = {
      is_correct: isCorrectAnswer,
      submitted_answer: submittedAnswer,
      num_hints_used: getNumHintsUsed(),
      puzzle_id: id,
      user_id: user && user.id,
      puzzle_time_sec: getStoredPuzzleTimeSec(id),
    };
    await trackAnswerSubmitted(answerSubmittedData, id);
  };
  const handleKeyboardEvent = (key) => {
    const wordInput = inputsRef.current;
    if (wordInput) {
      wordInput.handleKeyboardEvent(key);
    }
  };

  let puzzleButton;
  let giveUpButton;
  let timerDiv;
  switch (puzzleState) {
    case PUZZLE_STATES.WAITING_FOR_INPUT:
      puzzleButton = (
        <div
          className={`w-48 rounded-lg text-xl text-gray-500 dark:text-gray-700 font-semibold bg-gray-200 dark:bg-gray-700/20 cursor-not-allowed sm:py-3 py-2 ${
            timerEnabled ? "" : "sm:mb-4"
          }`}
        >
          Enter
        </div>
      );
      giveUpButton = (
        <div className="flex flex-col items-center sm:mt-3 mt-2">
          <div
            className={`gtm-reveal-answer-button w-48 text-base text-blue-500 hover:text-blue-700 font-semibold cursor-pointer`}
            onClick={giveUp}
          >
            Reveal answer
          </div>
        </div>
      );
      if (timerEnabled) {
        timerDiv = (
          <Timer
            id={id}
            puzzleComplete={puzzleComplete}
            inputsRef={inputsRef}
          ></Timer>
        );
      }
      break;
    case PUZZLE_STATES.READY_TO_SUBMIT:
      puzzleButton = (
        <div
          className={`w-48 rounded-lg text-xl text-white font-semibold bg-real-blue hover:bg-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 sm:py-3 py-2 cursor-pointer ${
            timerEnabled ? "" : "sm:mb-4"
          }`}
          onClick={handleSubmit}
        >
          Enter
        </div>
      );
      giveUpButton = (
        <div className="flex flex-col items-center sm:mt-3 mt-2">
          <div
            className={`gtm-reveal-answer-button w-48 text-base text-blue-500 hover:text-blue-700 font-semibold cursor-pointer`}
            onClick={giveUp}
          >
            Reveal answer
          </div>
        </div>
      );
      if (timerEnabled) {
        timerDiv = (
          <Timer
            id={id}
            puzzleComplete={puzzleComplete}
            inputsRef={inputsRef}
          ></Timer>
        );
      }
      break;
    case PUZZLE_STATES.SOLVED:
      puzzleButton = (
        <div
          className="gtm-solved-try-again-play-page-button w-48 rounded-lg text-xl text-gray-700 font-semibold bg-green-100 hover:bg-green-200 sm:py-3 py-2 flex gap-1.5 justify-center align-items-center cursor-pointer sm:mb-4"
          onClick={() => setSuccessModalOpenWithTracking(true)}
        >
          <CheckCircleIcon className="w-7 h-7 text-green-500"></CheckCircleIcon>
          Solved!
        </div>
      );
      break;
    case PUZZLE_STATES.GAVE_UP:
      puzzleButton = (
        <div
          className="w-48 rounded-lg text-xl font-semibold bg-red-100 hover:bg-red-200 dark:bg-red-900/20 dark:hover:bg-red-900/40 dark:text-red-500 sm:py-3 py-2 flex gap-2 justify-center align-items-center cursor-pointer"
          onClick={() => setSuccessModalOpenWithTracking(true)}
        >
          <XMarkIcon className="w-7 h-7 text-red-500"></XMarkIcon>Gave up!
        </div>
      );
      break;
    case PUZZLE_STATES.WRONG_ANSWER:
      puzzleButton = (
        <div
          className="w-64 rounded-lg text-lg text-real-red font-semibold bg-red-100 dark:bg-red-900/20 dark:text-red-500 sm:py-3 py-2 cursor-pointer"
          onClick={handleTryAgainClick}
        >
          Guess again!
        </div>
      );

      giveUpButton = (
        <div className="flex flex-col items-center sm:mt-3 mt-2">
          <div
            className="gtm-reveal-answer-button w-48 text-base text-blue-500 hover:text-blue-700 font-semibold cursor-pointer"
            onClick={giveUp}
          >
            Reveal answer
          </div>
        </div>
      );
      if (timerEnabled) {
        // hidden div just to mitigate layout shift
        timerDiv = (
          <div className="dark:text-white sm:mb-3 opacity-0">empty</div>
        );
      }
      break;
  }

  let playRandomTwoferButton;
  if (randomTwoferId && puzzleComplete) {
    playRandomTwoferButton = (
      <a
        onClick={async () => {
          await trackClick("play_random");
          // setId(randomTwoferId);
          setTimeout(() => {
            setId(randomTwoferId);    
            const p = puzzles.find(
              (puzzle) => puzzle.id == randomTwoferId
            );
            Router.push(`/daily/${p?.puzzle_number}`);        
          }, 50);
        }}
      >
        <button className="gtm-play-page-play-another-button w-48 text-white bg-hot-pink text-xl px-3 sm:py-[10px] py-[6px] cursor-pointer text-[18px] border-[2px] border-[#FF006E] hover:bg-[#CC0058] rounded-full flex gap-x-1 justify-center items-center font-bold">
          Play Another
          <ChevronRightIcon className="w-5 h-5" />
        </button>
      </a>
    );
  }

  const rightMenuContent = (
    <>
      <div className="flex flex-row gap-3 sm:pr-3">
        <div onClick={() => {GTMUtils.pushEventToGtm('archive_button'); setCalendarModalOpenWithTracking(true)}} className="gtm-archive-feature">
          <CalendarDaysIcon className="h-6 w-6 sm:h-7 sm:w-7 text-gray-500 hover:text-gray-800 cursor-pointer dark:text-gray-300 dark:hover:text-gray-400"></CalendarDaysIcon>
        </div>
        <div onClick={() => {GTMUtils.pushEventToGtm('settings_button'); setSettingsModalOpenWithTracking(true)}} className="gtm-settings-feature">
          <Cog8ToothIcon className="h-6 w-6 sm:h-7 sm:w-7 text-gray-500 hover:text-gray-800 cursor-pointer dark:text-gray-300 dark:hover:text-gray-400"></Cog8ToothIcon>
        </div>
        <div onClick={() => {GTMUtils.pushEventToGtm('help_button'); setInstructionsOpenWithTracking(true)}} className="gtm-help-feature">
          <QuestionMarkCircleIcon className="h-6 w-6 sm:h-7 sm:w-7 text-gray-500 hover:text-gray-800 cursor-pointer dark:text-gray-300 dark:hover:text-gray-400"></QuestionMarkCircleIcon>
        </div>
      </div>
    </>
  );

  const playRandomTwofer = () => {
    if (randomTwoferId) {
      setTimeout(() => {
        setId(randomTwoferId);
        const p = puzzles.find(
          (puzzle) => puzzle.id == randomTwoferId
        );
        Router.push(`/daily/${p?.puzzle_number}`);
      }, 50);
    }
  };

  function GradientText({ prompt }) {
    let text = prompt.prompt ?? prompt.alternate_prompt ?? prompt;
    let isDarkMode = prompt.isDarkMode;

    if (text == null) {
      return;
    }

    let gradientColors = [
      { stop: 0, color: "#F5A941" },
      { stop: 0.25, color: "#fb5607" },
      { stop: 0.5, color: "#ff006e" },
      { stop: 0.75, color: "#8338ec" },
      { stop: 1, color: "#3a86ff" },
    ];

    if (isDarkMode) {
      gradientColors = [
        { stop: 0, color: "#ffc525" },
        { stop: 0.25, color: "#ff6822" },
        { stop: 0.5, color: "#ff2483" },
        { stop: 0.75, color: "#ef5cff" },
        { stop: 1, color: "#3aa6ff" },
      ];
    }

    function lerp(a, b, t) {
      return a + (b - a) * t;
    }

    function getColorAtPosition(position) {
      let startColor, endColor;

      for (let i = 0; i < gradientColors.length - 1; i++) {
        if (
          position >= gradientColors[i].stop &&
          position <= gradientColors[i + 1].stop
        ) {
          startColor = gradientColors[i];
          endColor = gradientColors[i + 1];
          break;
        }
      }

      if (!startColor) {
        startColor = gradientColors[0];
      }

      if (!endColor) {
        endColor = gradientColors[gradientColors.length - 1];
      }

      const t =
        (position - startColor.stop) / (endColor.stop - startColor.stop);
      const startRGB = parseInt(startColor.color.substring(1), 16);
      const endRGB = parseInt(endColor.color.substring(1), 16);

      const r = Math.round(
        lerp((startRGB >> 16) & 255, (endRGB >> 16) & 255, t)
      );
      const g = Math.round(lerp((startRGB >> 8) & 255, (endRGB >> 8) & 255, t));
      const b = Math.round(lerp(startRGB & 255, endRGB & 255, t));

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    }

    const coloredText = [];

    for (let i = 0; i < text.length; i++) {
      const position = i / (text.length - 1);
      const color = getColorAtPosition(position);
      coloredText.push(
        <span key={i} style={{ color: color }}>
          {text[i]}
        </span>
      );
    }

    return <>{coloredText}</>;
  }
  const pageContent = (
    <>
      <SuccessModal
        puzzleState={puzzleState}
        open={showSuccessModal}
        setOpen={setSuccessModalOpen}
        setId={setId}
        setHistoryModalOpen={setHistoryModalOpenWithTracking}
        answer={answer}
        initialsHintUsed={showFirstInitials}
        syllablesHintUsed={showSyllables}
        moreDirectClueUsed={showMoreDirectClue}
        blurredDepictionUsed={showBlurredDepiction}
        puzzleNumber={puzzle_number}
        imageUrl={image}
        puzzleTimeSec={completedPuzzleTimeSec}
        timerEnabled={timerEnabled}
        historicalState={historicalState}
        randomTwoferId={randomTwoferId}
        mostRecentPuzzleId={mostRecentPuzzleId}
        viewportHeight={viewportHeight}
        scaleFactor={scaleFactor}
        experiments={experiments}
        puzzles={puzzles}
        puzzleDay={puzzle.day}
        setCalendarModalOpenWithTracking={setCalendarModalOpenWithTracking}
        isTodayPuzzle={isTodayPuzzle}
        currentDate={currentDate}
      ></SuccessModal>
      <CalendarModal
        open={calendarModalOpen}
        puzzles={puzzles}
        setOpen={setCalendarModalOpenWithTracking}
        historicalState={historicalState}
        mostRecentPuzzleId={mostRecentPuzzleId}
        viewportHeight={viewportHeight}
        experiments={experiments}
        setId={setId}
        calendarScrollPosition={calendarScrollPosition}
        setCalendarScrollPosition={setCalendarScrollPosition}
        isTodayPuzzle={isTodayPuzzle}
        currentDate={currentDate}
        ></CalendarModal>
      <HistoryModal
        open={historyModalOpen}
        setOpen={setHistoryModalOpenWithTracking}
        currentPuzzleDone={puzzleComplete}
        currentPuzzleSolved={puzzleState == PUZZLE_STATES.SOLVED}
        numHintsUsedToday={getNumHintsUsed()}
        answer={answer}
        imageUrl={image}
        puzzleNumber={puzzle_number}
        puzzleId={id}
        prompt={prompt}
        historicalState={historicalState}
        numSecondsToday={completedPuzzleTimeSec}
        mostRecentPuzzleId={mostRecentPuzzleId}
        viewportHeight={viewportHeight}
        scaleFactor={scaleFactor}
        isTodayPuzzle={isTodayPuzzle}
        currentDate={currentDate}
        ></HistoryModal>

      <div className="w-full m-auto sm:mt-4 my-2 text-gray-500 font-semibold leading-normal rounded-lg border-2 sm:p-6 p-2 clue bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
        <div
          className={`sm:text-3xl text-md ${
            isDarkMode ? "dark-clue-colored" : "clue-colored"
          } ${showMoreDirectClue ? "hidden" : ""}`}
        >
          <GradientText prompt={{ prompt, isDarkMode }} />
        </div>
        <div
          className={`flex sm:gap-4 gap-1 flex-col ${
            showMoreDirectClue ? "" : "hidden"
          }`}
        >
          <div className="sm:text-3xl text-md text-gray-500 dark:text-gray-400">
            {prompt}
          </div>
          <div
            className={`sm:text-3xl text-md gradient-bg ${
              isDarkMode ? " dark-clue-colored" : "clue-colored"
            }`}
          >
            <GradientText prompt={{ alternate_prompt, isDarkMode }} />
          </div>
        </div>
      </div>
      {puzzleComplete || (
        <Hints
          numHints={numHintsAvailable}
          hintState={hintState}
          puzzleState={puzzleState}
          puzzleId={id}
        ></Hints>
      )}
      <div
        className={`px-2 sm:px-0 m-auto flex flex-col items-center justify-center relative`}
      >
        {timerDiv}

        {/* shubik: adding image here and hiding it to ensure it gets preloaded */}
        <div className="relative w-64 h-64 hidden">
          <Image
            className="rounded-t-lg"
            src={image}
            alt={"Twofer Goofer image"}
            fill
            objectFit="contain"
            loading="eager"
          />
        </div>
        <div
          className={`flex flex-col sm:gap-4 gap-3 items-center`}
        >
          <div
            className={`absolute -left-64 ${
              showBlurredDepiction ? "sm:block" : ""
            } hidden`}
          >
            <div className="relative w-64 h-64">
              <Image
                className="rounded-lg"
                src={blurred_image}
                alt={"Twofer Goofer blurred image"}
                fill
                objectFit="contain"
                loading="eager"
              />
            </div>
          </div>
          <WordInput
            id={id}
            ref={inputsRef}
            answer={answer}
            wordLengths={wordLengths}
            numSyllables={numSyllables}
            firstLetters={firstLetters}
            showSyllables={showSyllables}
            showFirstInitials={showFirstInitials}
            puzzleState={puzzleState}
            handleSubmit={handleSubmit}
            setPuzzleState={setPuzzleState}
            isMobile={isMobile}
            disableCustomKeyboard={disableCustomKeyboard}
          ></WordInput>
          <div className="flex flex-col md:flex-row gap-y-3 md:gap-y-0 gap-x-3 items-start">
            {puzzleButton}
            {playRandomTwoferButton}
          </div>
          
          <SmartErrors
            submittedAnswer={mostRecentlySubmittedAnswer}
            answer={answer}
            alternateAnswer={alternate_answer}
            experiments={experiments}
          ></SmartErrors>
          {giveUpButton}
          
        </div>
      </div>
      {dbg && <div>{JSON.stringify(historicalState)}</div>}
      <div className="flex justify-center">
        <div
          className={`${
            !showBlurredDepiction ? "hidden" : ""
          } sm:hidden`}
        >
          <div className="relative w-64 h-64">
            <Image
              className="rounded-lg"
              src={blurred_image}
              alt={"Twofer Goofer hint image"}
              fill
              objectFit="contain"
              loading="eager"
            />
          </div>
        </div>
      </div>

      {/* <Keyboard
        puzzleState={puzzleState}
        handleKeyboardEvent={handleKeyboardEvent}
        disableCustomKeyboard={disableCustomKeyboard}
      ></Keyboard> */}
      <MockIOSKeyboard />
    </>
  );
  return (
    <BasePage
      pageContent={pageContent}
      rightMenuContent={rightMenuContent}
      instructionsModalOpen={instructionsModalOpen}
      setInstructionsOpenHandler={setInstructionsOpenHandler}
      isDarkMode={isDarkMode}
      settingsModalOpen={settingsModalOpen}
      timerEnabled={timerEnabled}
      setTimerEnabledWithTracking={setTimerEnabledWithTracking}
      disableCustomKeyboard={disableCustomKeyboard}
      setDisableCustomKeyboardWithTracking={
        setDisableCustomKeyboardWithTracking
      }
      darkModeForced={darkModeForced}
      setDarkModeForced={setDarkModeForced}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      setSettingsModalOpenWithTracking={setSettingsModalOpenWithTracking}
      setInstructionsOpenWithTracking={setInstructionsOpenWithTracking}
      playRandomTwofer={playRandomTwofer}
      enableSettingsModal={true}
      viewportHeight={viewportHeight}
      scaleFactor={scaleFactor}
      setCalendarModalOpenWithTracking={setCalendarModalOpenWithTracking}
    ></BasePage>
  );
}
